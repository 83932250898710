import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'companies',
    data: {
      breadcrumb: 'Companies'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
      },
      {
        path: ':companyId/employees',
        loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
        data: {
          breadcrumb: 'Employees'
        }
      }
    ]
  },
  {
    path: 'activity-areas',
    loadChildren: () => import('./activity-area/activity-area.module').then(m => m.ActivityAreaModule),
    data: {
      breadcrumb: 'Activity Areas'
    }
  },
  {
    path: 'requests',
    loadChildren: () => import('./request/request.module').then(m => m.RequestModule),
    data: {
      breadcrumb: 'Requests'
    }
  },
  {
    path: 'invoicing',
    loadChildren: () => import('./invoicing/invoicing.module').then(m => m.InvoicingModule),
    data: {
      breadcrumb: 'Invoicing'
    }
  },
  {
    path: 'contracts',
    loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule),
    data: {
      breadcrumb: 'Contracts'
    }
  },
  {
    path: 'purchase-orders',
    loadChildren: () => import('./purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
    data: {
      breadcrumb: 'Purchase orders'
    }
  },
  {
    path: 'mailing',
    loadChildren: () => import('./mailing/mailing.module').then(m => m.MailingModule),
    data: {
      breadcrumb: 'Mails'
    }
  },

  {
    path: 'marketplace',
    loadChildren: () => import('./marketplace/marketplace.module').then(m => m.MarketplaceModule),
    data: {
      breadcrumb: 'Marketplace'
    }
  },
  {
    path: 'activity-report',
    loadChildren: () => import('./activity-report/activity-report.module').then(m => m.ActivityReportModule),
    data: {
      breadcrumb: 'Activity Report'
    }
  },
  {
    path: 'configuration',
    loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
    data: {
      breadcrumb: 'Configuration'
    }
  },
  {
    path: 'legal-documents',
    loadChildren: () => import('./legal-document/legal-document.module').then(m => m.LegalDocumentModule),
    data: {
      breadcrumb: 'Legal documents'
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    data: {
      breadcrumb: 'Reports'
    }
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: 'Users'
    }
  },
  {
    path: 'fees',
    loadChildren: () => import('./fees/fees.module').then(m => m.FeesModule),
    data: {
      breadcrumb: 'Fees'
    }
  },
  {
    path: 'on-call',
    loadChildren: () => import('./on-call/on-call.module').then(m => m.OnCallModule),
    data: {
      breadcrumb: 'On-call-nav'
    }
  },
  {
    path: 'tax-configuration-country',
    loadChildren: () => import('./tax-configuration-country/tax-configuration-country.module').then(m => m.TaxConfigurationCountryModule),
    data: {
      breadcrumb: 'tax-configuration-country'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
