export const regex = {
  companyName: '^[a-zA-ZÀ-ÿ0-9](?!.*[^\\w\\s]{2})[a-zA-ZÀ-ÿ0-9\\s\\W]*[a-zA-ZÀ-ÿ0-9]$',
  projectName: '^[a-zA-ZÀ-ÿ0-9]+(?:[ ]?(?:[-.\'/_:][ ]?)?[a-zA-ZÀ-ÿ0-9]+)*$',
  companyNameWithSpecialChars: '^(?! )[a-zA-ZÀ-ÿ0-9 \\&\\,\\.\\%\\?\\/\\;]+$',
  firstName: '^[a-zA-ZÀ-ÿ]+(?:[\' -][a-zA-ZÀ-ÿ]+)*$',
  lastName: '^[a-zA-ZÀ-ÿ]+(?:[\' -][a-zA-ZÀ-ÿ]+)*$',
  email: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]{2,}\\.[a-z]{2,20}$',
  shareCapital: '^[0-9]+(?:[ ][A-Z]{3})',

  commentName: '^(?:[A-Za-zÀ-ÿ0-9,\'.-]+[ ]?)*$',

  phoneNumber: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[- \./0-9]*$',

  title: '^[a-zA-ZÀ-ÿ]+(?:[ -\'][a-zA-ZÀ-ÿ]+)*$',

  incorporationNumber: '^[A-Z0-9]*$',
  city: '^[a-zA-ZÀ-ÿ]+(?:[ \'-][a-zA-ZÀ-ÿ0-9]+)*$',
  street: '^(?:[A-Za-zÀ-ÿ0-9,\'.-]+[ ]?)*$',
  postCode: '^[A-Z0-9]*$',
  stateOrProvince: '^[a-zA-ZÀ-ÿ]+(?:[ .\',-][a-zA-ZÀ-ÿ]+)*$',
  country: '^[a-zA-ZÀ-ÿ]+(?:[ \'-][a-zA-ZÀ-ÿ]+)*$',

  tenDigitAndTwoDecimalPlaces: '^[0-9]{1,10}(\\.[0-9]{1,2})?$',
  tenDigitAndTwoDecimalPlacesWithRange: '^[0-9]{1,10}(\\.(25|5|75|0))?$',
  twoDigitAndTwoDecimalPlaces: '^[0-9]{1,2}(\\.[0-9]{1,2})?$',

  vatNumber: '^[A-Z]{2}([0-9]{11})$',
  registrationNumber: '^[0-9]*$',

  bicOrSwift: '^[A-Z]{6}([A-Z0-9]{2}|[A-Z0-9]{5})$',

  onCallSort: '^(purchaseOrderNumber|buyerCompanyName|supplierCompanyName|buyer.projectName|supplier.employeeName|config.workItemReference|config.workUnit|time|quantity|priceAmount|unitPrice|date|status|submissionDate)\,(asc|desc)$',
  feeSort: '^(buyer.projectName|purchaseOrderNumber|buyerCompanyName|supplierCompanyName|supplier.employeeName|category|amountExcludingTax|date|status|submissionDate)\,(asc|desc)$',
  templateSort: '^(templateName)\,(asc|desc)$',
  internationalPostalCode: '^[a-zA-ZÀ-ÿ0-9]+(?:[ .\'/-_:][a-zA-ZÀ-ÿ0-9]+)*$',
  maxLengthEscapeSpace: '^(?:\\S\\s*){1,34}$',
  filename: '^[\\wÀ-ÿ]+([\\s]?[-_,\'"]?[\\s]?[\\wÀ-ÿ])*$',
  alphanumeric: '^[a-zA-ZÀ-ÿ0-9]+(?: [a-zA-ZÀ-ÿ0-9]+)*$',
  taxRatePattern: '^\\d+(\\.\\d{1,3})?$',
  whole_numbers_2_decimal_place: '^-?\\d+(\\.\\d{1,2})?$',
  fractional_number_2_decimals: '^-?0(\\.\\d{1,2})?$',
  whole_numbers_2_decimal_place_quarter: '^-?\\d+(\\.(25|5|50|75))?$',
  whole_numbers_positive: '^\\d+$',
  whole_numbers_positive_negative: '^-?\\d+$'
};
